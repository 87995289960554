import Vue from 'vue';
import axios from 'axios';
import i18n from './helpers/i18n';
import App from './App.vue';

Vue.prototype.$http = axios;

const el = document.getElementById('app-support');

if (el) {
    const config = JSON.parse(el.dataset.config);

    new Vue({
        render: h => h(App),
        beforeMount() {
            this.$config = config;
        },
        i18n: i18n(config),
    }).$mount(el);
}
