var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "support-modal",
      attrs: { role: "dialog", "aria-labelledby": "support-modal-title" },
    },
    [
      _c("div", { staticClass: "support-modal__header" }, [
        _c(
          "h6",
          {
            staticClass: "support-modal__title",
            attrs: { id: "support-modal-title" },
          },
          [_vm._v(_vm._s(_vm.$t("modalTitle")))]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "support-modal__close",
            attrs: { "aria-label": _vm.$t("ariaCloseButton") },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onClose.apply(null, arguments)
              },
            },
          },
          [_c("i", { staticClass: "icon-cross" })]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "support-modal__content" }, [
        _c(
          "div",
          {
            staticClass: "support",
            class: { "support--loading": _vm.loading },
          },
          [
            _c("div", { attrs: { role: "alert", "aria-atomic": "true" } }, [
              _vm.confirmation
                ? _c("div", { staticClass: "alert alert-success mb-0" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("confirmation")) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            !_vm.confirmation
              ? _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.onSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "formbody" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-6" }, [
                          _c(
                            "div",
                            {
                              staticClass: "widget widget-text",
                              class: {
                                "widget--error": _vm.v$.form.name.$error,
                              },
                            },
                            [
                              _c(
                                "label",
                                { attrs: { for: "app-support-name" } },
                                [_vm._v(_vm._s(_vm.$t("name")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  attrs: {
                                    role: "alert",
                                    "aria-atomic": "true",
                                  },
                                },
                                [
                                  !_vm.v$.form.name.required
                                    ? _c("p", { staticClass: "error" }, [
                                        _vm._v(_vm._s(_vm.$t("nameRequired"))),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.name,
                                    expression: "form.name",
                                  },
                                ],
                                staticClass: "text",
                                attrs: {
                                  type: "text",
                                  id: "app-support-name",
                                  required: "",
                                },
                                domProps: { value: _vm.form.name },
                                on: {
                                  focus: _vm.hideConfirmation,
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "name",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c(
                            "div",
                            {
                              staticClass: "widget widget-text",
                              class: {
                                "widget--error": _vm.v$.form.email.$error,
                              },
                            },
                            [
                              _c(
                                "label",
                                { attrs: { for: "app-support-email" } },
                                [_vm._v(_vm._s(_vm.$t("email")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  attrs: {
                                    role: "alert",
                                    "aria-atomic": "true",
                                  },
                                },
                                [
                                  !_vm.v$.form.email.required
                                    ? _c("p", { staticClass: "error" }, [
                                        _vm._v(_vm._s(_vm.$t("emailRequired"))),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.v$.form.email.email
                                    ? _c("p", { staticClass: "error" }, [
                                        _vm._v(_vm._s(_vm.$t("emailInvalid"))),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.email,
                                    expression: "form.email",
                                  },
                                ],
                                staticClass: "text",
                                attrs: {
                                  type: "email",
                                  id: "app-support-email",
                                  required: "",
                                },
                                domProps: { value: _vm.form.email },
                                on: {
                                  focus: _vm.hideConfirmation,
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "email",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-12" }, [
                          _c(
                            "div",
                            {
                              staticClass: "widget widget-textarea",
                              class: {
                                "widget--error": _vm.v$.form.message.$error,
                              },
                            },
                            [
                              _c(
                                "label",
                                { attrs: { for: "app-support-message" } },
                                [_vm._v(_vm._s(_vm.$t("message")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  attrs: {
                                    role: "alert",
                                    "aria-atomic": "true",
                                  },
                                },
                                [
                                  !_vm.v$.form.message.required
                                    ? _c("p", { staticClass: "error" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("messageRequired"))
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.v$.form.message.length
                                    ? _c("p", { staticClass: "error" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .$t("messageMaxLength")
                                              .replace(
                                                "%d",
                                                _vm.v$.form.message.maxLength
                                                  .$params.max
                                              )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.message,
                                    expression: "form.message",
                                  },
                                ],
                                attrs: {
                                  name: "message",
                                  id: "app-support-message",
                                  cols: "30",
                                  rows: "10",
                                  required: "",
                                },
                                domProps: { value: _vm.form.message },
                                on: {
                                  focus: _vm.hideConfirmation,
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "message",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-12" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "submit" },
                            },
                            [_vm._v(_vm._s(_vm.$t("submit")))]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }