<template>
    <div class="support-modal" role="dialog" aria-labelledby="support-modal-title">
        <div class="support-modal__header">
            <h6 id="support-modal-title" class="support-modal__title">{{ $t('modalTitle') }}</h6>
            <button class="support-modal__close" @click.prevent="onClose" :aria-label="$t('ariaCloseButton')"><i class="icon-cross"></i></button>
        </div>
        <div class="support-modal__content">
            <div class="support" :class="{ 'support--loading': loading }">
                <div role="alert" aria-atomic="true">
                    <div class="alert alert-success mb-0" v-if="confirmation">
                        {{ $t('confirmation') }}
                    </div>
                </div>

                <form @submit.prevent="onSubmit" v-if="!confirmation">
                    <div class="formbody">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="widget widget-text" :class="{ 'widget--error': v$.form.name.$error }">
                                    <label for="app-support-name">{{ $t('name') }}</label>
                                    <div role="alert" aria-atomic="true">
                                        <p class="error" v-if="!v$.form.name.required">{{ $t('nameRequired') }}</p>
                                    </div>

                                    <input type="text" id="app-support-name" class="text" v-model="form.name" @focus="hideConfirmation" required>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="widget widget-text" :class="{ 'widget--error': v$.form.email.$error }">
                                    <label for="app-support-email">{{ $t('email') }}</label>

                                    <div role="alert" aria-atomic="true">
                                        <p class="error" v-if="!v$.form.email.required">{{ $t('emailRequired') }}</p>
                                        <p class="error" v-if="!v$.form.email.email">{{ $t('emailInvalid') }}</p>
                                    </div>

                                    <input type="email" id="app-support-email" class="text" v-model="form.email" @focus="hideConfirmation" required>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="widget widget-textarea" :class="{ 'widget--error': v$.form.message.$error }">
                                    <label for="app-support-message">{{ $t('message') }}</label>

                                    <div role="alert" aria-atomic="true">
                                        <p class="error" v-if="!v$.form.message.required">{{ $t('messageRequired') }}</p>
                                        <p class="error" v-if="!v$.form.message.length">{{ $t('messageMaxLength').replace('%d', v$.form.message.maxLength.$params.max) }}</p>
                                    </div>

                                    <textarea name="message" id="app-support-message" cols="30" rows="10" v-model="form.message" @focus="hideConfirmation" required></textarea>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button type="submit" class="btn btn-primary">{{ $t('submit') }}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import error from '../_helpers/error-handler';
    import { useVuelidate } from '@vuelidate/core';
    import { required, email, maxLength } from '@vuelidate/validators';

    export default {
        name: 'App',
        setup() {
            return {
                v$: useVuelidate(),
            };
        },
        data() {
            return {
                confirmation: false,
                loading: false,
                form: {
                    email: '',
                    name: '',
                    message: '',
                },
            };
        },
        validations() {
            return {
                form: {
                    name: { required },
                    email: { required, email },
                    message: { required, maxLength: maxLength(this.$parent.$config.messageMaxLength) },
                }
            };
        },
        methods: {
            hideConfirmation() {
                this.confirmation = false;
            },
            onClose() {
                document.dispatchEvent(new window.CustomEvent('app-support-close'));
                this.confirmation = false;
            },
            onSubmit() {
                this.v$.$touch();
                this.hideConfirmation();

                if (this.v$.$invalid) {
                    const firstErrorInput = Object.keys(this.v$.form.$model).find(key => this.v$.form[key].$invalid);

                    // Focus the first element with error
                    if (firstErrorInput) {
                        document.getElementById(`app-support-${firstErrorInput}`).focus();
                    }

                    return;
                }

                this.loading = true;
                this.$http.post(this.$parent.$config.submitUrl, this.form).catch(error).then(() => {
                    this.confirmation = true;
                    this.loading = false;

                    // Reset the form
                    this.$set(this.form, 'name', '');
                    this.$set(this.form, 'email', '');
                    this.$set(this.form, 'message', '');
                    this.v$.$reset();
                });
            },
        }
    }
</script>
