import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

export default config => {
    return new VueI18n({
        locale: document.documentElement.lang,
        fallbackLocale: 'de',
        messages: { [document.documentElement.lang]: config.labels },
    });
};
